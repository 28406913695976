exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cart-index-jsx": () => import("./../../../src/pages/cart/index.jsx" /* webpackChunkName: "component---src-pages-cart-index-jsx" */),
  "component---src-pages-forelasningar-index-js": () => import("./../../../src/pages/forelasningar/index.js" /* webpackChunkName: "component---src-pages-forelasningar-index-js" */),
  "component---src-pages-kalkylator-js": () => import("./../../../src/pages/kalkylator.js" /* webpackChunkName: "component---src-pages-kalkylator-js" */),
  "component---src-pages-kurser-index-jsx": () => import("./../../../src/pages/kurser/index.jsx" /* webpackChunkName: "component---src-pages-kurser-index-jsx" */),
  "component---src-pages-kurser-shopify-product-handle-jsx": () => import("./../../../src/pages/kurser/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-kurser-shopify-product-handle-jsx" */),
  "component---src-pages-moteskostnadskalkylatorn-js": () => import("./../../../src/pages/moteskostnadskalkylatorn.js" /* webpackChunkName: "component---src-pages-moteskostnadskalkylatorn-js" */),
  "component---src-pages-personalomsattningskalkylatorn-js": () => import("./../../../src/pages/personalomsattningskalkylatorn.js" /* webpackChunkName: "component---src-pages-personalomsattningskalkylatorn-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-company-courses-js": () => import("./../../../src/templates/company-courses.js" /* webpackChunkName: "component---src-templates-company-courses-js" */),
  "component---src-templates-consultants-js": () => import("./../../../src/templates/consultants.js" /* webpackChunkName: "component---src-templates-consultants-js" */),
  "component---src-templates-consultants-page-js": () => import("./../../../src/templates/consultants-page.js" /* webpackChunkName: "component---src-templates-consultants-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-customer-content-js": () => import("./../../../src/templates/customer-content.js" /* webpackChunkName: "component---src-templates-customer-content-js" */),
  "component---src-templates-erbjudande-js": () => import("./../../../src/templates/erbjudande.js" /* webpackChunkName: "component---src-templates-erbjudande-js" */),
  "component---src-templates-foretagskurs-js": () => import("./../../../src/templates/foretagskurs.js" /* webpackChunkName: "component---src-templates-foretagskurs-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-leadership-program-js": () => import("./../../../src/templates/leadership-program.js" /* webpackChunkName: "component---src-templates-leadership-program-js" */),
  "component---src-templates-lms-js": () => import("./../../../src/templates/lms.js" /* webpackChunkName: "component---src-templates-lms-js" */),
  "component---src-templates-office-space-js": () => import("./../../../src/templates/office-space.js" /* webpackChunkName: "component---src-templates-office-space-js" */),
  "component---src-templates-plain-page-js": () => import("./../../../src/templates/plain-page.js" /* webpackChunkName: "component---src-templates-plain-page-js" */),
  "component---src-templates-snackademin-js": () => import("./../../../src/templates/snackademin.js" /* webpackChunkName: "component---src-templates-snackademin-js" */),
  "component---src-templates-tack-js": () => import("./../../../src/templates/tack.js" /* webpackChunkName: "component---src-templates-tack-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-talks-js": () => import("./../../../src/templates/talks.js" /* webpackChunkName: "component---src-templates-talks-js" */),
  "component---src-templates-utan-kostnad-js": () => import("./../../../src/templates/utan-kostnad.js" /* webpackChunkName: "component---src-templates-utan-kostnad-js" */)
}

